import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, TitleStrategy } from '@angular/router';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiModule, Configuration } from '@api-open';
import { AuditApiModule, Configuration as ConfigurationAudit } from '@api-open-audit';
import { Configuration as ConfigurationShowback, ShowbackApiModule } from '@api-open-showback';
import { ErrorInterceptor, TokenInterceptor } from '@tk/app/interceptors';
import { ToastManagerComponent } from '@tk/app/toast-manager';
import { DatepickerModule } from '@tk/shared/itera-ui-kit/inputs/datepicker/datepicker.module';
import { CustomizationService } from '@tk/shared/lib';
import { CONFIG } from 'src/config';
import { PageTitleStrategy } from 'src/page-title-strategy.service';

import { AppComponent } from './app.component';
import { alertsIcons } from './icons/alerts';
import { arrowsIcons } from './icons/arrows';
import { chartsIcons } from './icons/charts';
import { communicationIcons } from './icons/communication';
import { developmentIcons } from './icons/development';
import { editorIcons } from './icons/editor';
import { educationIcons } from './icons/education';
import { filesIcons } from './icons/files';
import { financeIcons } from './icons/finance';
import { generalIcons } from './icons/general';
import { imagesIcons } from './icons/images';
import { layoutIcons } from './icons/layout';
import { logoIcons } from './icons/logo';
import { mapsIcons } from './icons/maps';
import { mediaIcons } from './icons/media';
import { oldIcons } from './icons/old';
import { securityIcons } from './icons/security';
import { shapesIcons } from './icons/shapes';
import { taikunIcons } from './icons/taikun';
import { timeIcons } from './icons/time';
import { usersIcons } from './icons/users';
import { appRoutes } from './routes-lazy';

const icons = [
  ...alertsIcons,
  ...arrowsIcons,
  ...chartsIcons,
  ...communicationIcons,
  ...developmentIcons,
  ...editorIcons,
  ...educationIcons,
  ...filesIcons,
  ...financeIcons,
  ...generalIcons,
  ...imagesIcons,
  ...layoutIcons,
  ...logoIcons,
  ...mapsIcons,
  ...oldIcons,
  ...securityIcons,
  ...shapesIcons,
  ...taikunIcons,
  ...timeIcons,
  ...usersIcons,
  ...mediaIcons,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: CONFIG.apiUrlGenerated,
        }),
    ),

    ShowbackApiModule.forRoot(
      () =>
        new ConfigurationShowback({
          basePath: CONFIG.apiUrlGenerated,
        }),
    ),
    AuditApiModule.forRoot(
      () =>
        new ConfigurationAudit({
          basePath: CONFIG.apiUrlGenerated,
        }),
    ),
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { paramsInheritanceStrategy: 'always', bindToComponentInputs: true }),
    ToastManagerComponent,
    MatDialogModule,
    DatepickerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (customizationService: CustomizationService) => (): Observable<unknown> =>
        customizationService.navCustomization$.pipe(
          catchError((_err) => {
            return of(null);
          }),
        ),
      deps: [CustomizationService],
      multi: true,
    },
    provideSvgIconsConfig({
      icons,
      sizes: {
        md: '1.125rem',
      },
      defaultSize: 'md',
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
