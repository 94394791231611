import { appHardDriveIcon } from './hard-drive';
import { appMouseIcon } from './mouse';
import { appPauseSquareIcon } from './pause-square';
import { appPlayCircleIcon } from './play-circle';
import { appPlayIcon } from './play';
import { appPower01Icon } from './power-01';
import { appPrinterIcon } from './printer';
import { appSignal01Icon } from './signal-01';
import { appStopCircleIcon } from './stop-circle';
import { appStopIcon } from './stop';
import { appVolumeMaxIcon } from './volume-max';
import { appVolumeXIcon } from './volume-x';
export const mediaIcons = [appHardDriveIcon, appMouseIcon, appPauseSquareIcon, appPlayCircleIcon, appPlayIcon, appPower01Icon, appPrinterIcon, appSignal01Icon, appStopCircleIcon, appStopIcon, appVolumeMaxIcon, appVolumeXIcon];
