import { appActivityHeartIcon } from './activity-heart';
import { appActivityIcon } from './activity';
import { appAnchorIcon } from './anchor';
import { appArchiveIcon } from './archive';
import { appAtSignIcon } from './at-sign';
import { appBookmarkIcon } from './bookmark';
import { appBuilding06Icon } from './building-06';
import { appCheckCircleIcon } from './check-circle';
import { appCheckIcon } from './check';
import { appCopy06Icon } from './copy-06';
import { appDotsHorizontalIcon } from './dots-horizontal';
import { appDotsVerticalIcon } from './dots-vertical';
import { appDownload01Icon } from './download-01';
import { appEdit02Icon } from './edit-02';
import { appEdit03Icon } from './edit-03';
import { appEdit05Icon } from './edit-05';
import { appEqualIcon } from './equal';
import { appEyeOffIcon } from './eye-off';
import { appEyeIcon } from './eye';
import { appFilterFunnel02Icon } from './filter-funnel-02';
import { appHash02Icon } from './hash-02';
import { appHeartIcon } from './heart';
import { appInfoCircleIcon } from './info-circle';
import { appLinkExternal02Icon } from './link-external-02';
import { appLoading02Icon } from './loading-02';
import { appLogOut01Icon } from './log-out-01';
import { appMenu05Icon } from './menu-05';
import { appMinusCircleIcon } from './minus-circle';
import { appMinusIcon } from './minus';
import { appPlaceholderIcon } from './placeholder';
import { appPlusCircleIcon } from './plus-circle';
import { appPlusIcon } from './plus';
import { appSearchMdIcon } from './search-md';
import { appSearchSmIcon } from './search-sm';
import { appSettings01Icon } from './settings-01';
import { appSettings04Icon } from './settings-04';
import { appShare07Icon } from './share-07';
import { appSlashCircle01Icon } from './slash-circle-01';
import { appToggle01LeftIcon } from './toggle-01-left';
import { appToggle01RightIcon } from './toggle-01-right';
import { appTool01Icon } from './tool-01';
import { appTool02Icon } from './tool-02';
import { appTrash01Icon } from './trash-01';
import { appTrash03Icon } from './trash-03';
import { appVirusIcon } from './virus';
import { appXCircleIcon } from './x-circle';
import { appXCloseIcon } from './x-close';
import { appXIcon } from './x';
import { appZapIcon } from './zap';
export const generalIcons = [appActivityHeartIcon, appActivityIcon, appAnchorIcon, appArchiveIcon, appAtSignIcon, appBookmarkIcon, appBuilding06Icon, appCheckCircleIcon, appCheckIcon, appCopy06Icon, appDotsHorizontalIcon, appDotsVerticalIcon, appDownload01Icon, appEdit02Icon, appEdit03Icon, appEdit05Icon, appEqualIcon, appEyeOffIcon, appEyeIcon, appFilterFunnel02Icon, appHash02Icon, appHeartIcon, appInfoCircleIcon, appLinkExternal02Icon, appLoading02Icon, appLogOut01Icon, appMenu05Icon, appMinusCircleIcon, appMinusIcon, appPlaceholderIcon, appPlusCircleIcon, appPlusIcon, appSearchMdIcon, appSearchSmIcon, appSettings01Icon, appSettings04Icon, appShare07Icon, appSlashCircle01Icon, appToggle01LeftIcon, appToggle01RightIcon, appTool01Icon, appTool02Icon, appTrash01Icon, appTrash03Icon, appVirusIcon, appXCircleIcon, appXCloseIcon, appXIcon, appZapIcon];
