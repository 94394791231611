import { appAmazonEksLogoIcon } from './amazon-eks-logo';
import { appAwsLogoIcon } from './aws-logo';
import { appAzureAksLogoIcon } from './azure-aks-logo';
import { appAzureLogoIcon } from './azure-logo';
import { appDebianLogoIcon } from './debian-logo';
import { appGoogleGkeLogoIcon } from './google-gke-logo';
import { appGoogleLogoIcon } from './google-logo';
import { appKubernetesLogoIcon } from './kubernetes-logo';
import { appMicrosoftLogoIcon } from './microsoft-logo';
import { appOpenshiftLogoIcon } from './openshift-logo';
import { appOpenstackLogoIcon } from './openstack-logo';
import { appProxmoxLogoIcon } from './proxmox-logo';
import { appRedhatLogoIcon } from './redhat-logo';
import { appSuseLogoIcon } from './suse-logo';
import { appTaikunLogoSmallIcon } from './taikun-logo-small';
import { appTaikunLogoIcon } from './taikun-logo';
import { appTanzuLogoIcon } from './tanzu-logo';
import { appUbuntuLogoIcon } from './ubuntu-logo';
import { appUndefinedIcon } from './undefined';
import { appVirtualClusterLogoIcon } from './virtual-cluster-logo';
import { appVsphereLogoIcon } from './vsphere-logo';
import { appZadaraLogoIcon } from './zadara-logo';
import { appZededaLogoIcon } from './zededa-logo';
export const logoIcons = [appAmazonEksLogoIcon, appAwsLogoIcon, appAzureAksLogoIcon, appAzureLogoIcon, appDebianLogoIcon, appGoogleGkeLogoIcon, appGoogleLogoIcon, appKubernetesLogoIcon, appMicrosoftLogoIcon, appOpenshiftLogoIcon, appOpenstackLogoIcon, appProxmoxLogoIcon, appRedhatLogoIcon, appSuseLogoIcon, appTaikunLogoSmallIcon, appTaikunLogoIcon, appTanzuLogoIcon, appUbuntuLogoIcon, appUndefinedIcon, appVirtualClusterLogoIcon, appVsphereLogoIcon, appZadaraLogoIcon, appZededaLogoIcon];
